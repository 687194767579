import { Badge } from '@asy/shadcn-ui/components/badge';
import { Button } from '@asy/shadcn-ui/components/button';
import { Mail, Phone } from 'lucide-react';
import { EnquireDialog } from '~/components/enquire-dialog';
import { Typography } from '~/components/typography';

export function ConnectWithAbhyasSection() {
  return (
    <section className="w-full">
      <div className="container px-4 md:px-6">
        <div>
          <div className="grid gap-8 lg:grid-cols-2 lg:gap-12">
            <div className="space-y-4">
              <Badge className="rounded-lg bg-primary/10 py-1 text-sm font-normal text-primary">Connect With Us</Badge>
              <blockquote className="space-y-2">
                <Typography variant="h2" as="h1">
                  Connect with Abhyas-certified instructors to handhold you on your journey of expansion.
                </Typography>
                <footer className="text-sm text-muted-foreground">
                  *Schedule a 15 min video conference with Abhyas
                </footer>
              </blockquote>
              <EnquireDialog
                triggerButton={
                  <Button size="lg" className="mt-4 w-full sm:w-auto">
                    Schedule a Call
                  </Button>
                }
              />
            </div>
            <div className="flex flex-col justify-center space-y-4 rounded-lg p-6 lg:p-8">
              <div className="space-y-2">
                <h3 className="font-semibold">Get in Touch</h3>
                <div className="flex items-center space-x-2 text-muted-foreground">
                  <Mail className="h-4 w-4" />
                  <a href="mailto:info@abhyas.mu" className="hover:text-primary">
                    info@abhyas.mu
                  </a>
                </div>
                <div className="flex items-center space-x-2 text-muted-foreground">
                  <Phone className="h-4 w-4" />
                  <a href="tel:+2305274555" className="hover:text-primary">
                    +230 5274 5555
                  </a>
                </div>
              </div>
              <div className="rounded-lg bg-background">
                <p className="text-sm text-muted-foreground">
                  Our team is available Monday through Friday, 9am to 5pm GMT+4. We usually respond within 24 hours.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
